import Link from "next/link";
import { useRouter } from "next/router";

const LanguageSwitcher = () => {
  const router = useRouter();

  return (
    <div>
      <Link
        href={router.asPath}
        locale={router.locale === "en" ? "fr" : "en"}
        scroll={false}
      >
        <button className="text-indigo-600">
          {router.locale === "en" ? "Fr" : "En"}
        </button>
      </Link>
    </div>
  );
};

export default LanguageSwitcher;
