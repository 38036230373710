import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Image from "next/image";
import { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";

import Hero from "../Hero";
import LanguageSwitcher from "../LanguageSwitcher";

const DynamicCalendlyNoSSR = dynamic(() => import("../Calendly"), {
  ssr: false,
});

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [resizeLogo, setResizeLogo] = useState(true);
  const { t } = useTranslation();

  const handleScroll = () => {
    if (window.pageYOffset > 70) {
      setResizeLogo(false);
    } else {
      setResizeLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className="w-full mx-auto ">
        <StyledUl
          open={open}
          className="sm:flex sm:justify-evenly sm:items-center w-full mx-auto bg-primary"
        >
          <div className="pt-4 ">
            <StyledLogo resizeLogo={resizeLogo} className="">
              <AnchorLink href="#hero">
                <Image
                  src="/images/WebFlex.png"
                  alt="Logo"
                  layout="fill"
                  quality={100}
                />
              </AnchorLink>
            </StyledLogo>
          </div>
          <StyledNavOptions
            open={open}
            className="shadow-2xl sm:shadow-none sm:flex sm:justify-evenly items-center w-8/12 p-4 "
          >
            <li className="hover: cursor-pointer">
              <AnchorLink href="#AboutUs" onClick={() => setOpen(false)}>
                {t("about-us")}
              </AnchorLink>
            </li>
            <li className="hover: cursor-pointer">
              <AnchorLink href="#RecentWork" onClick={() => setOpen(false)}>
                {t("recent-work")}
              </AnchorLink>
            </li>
            <li className="hover: cursor-pointer">
              <AnchorLink href="#Services" onClick={() => setOpen(false)}>
                {t("services")}
              </AnchorLink>
            </li>
            <li className="hover: cursor-pointer">
              <AnchorLink href="#contact" onClick={() => setOpen(false)}>
                {t("contact")} {t("us")}
              </AnchorLink>
            </li>
            <li>
              <DynamicCalendlyNoSSR />
            </li>
            <li>
              <LanguageSwitcher />
            </li>
          </StyledNavOptions>
        </StyledUl>

        {/* Hamburger */}
        <Hamburger
          open={open}
          className="sm:hidden fixed top-5 right-10 outline-none"
          onClick={() => setOpen(!open)}
        >
          <div className="w-8 h-1 bg-black rounded-full"></div>
          <div className="w-8 h-1 bg-black my-2 rounded-full"></div>
          <div className="w-5 h-1 bg-black rounded-full"></div>
        </Hamburger>

        <HamburgerClose
          open={open}
          className="sm:hidden fixed top-1 right-7 outline-none"
          onClick={() => setOpen(!open)}
        >
          <IoClose size={60} />
        </HamburgerClose>

        <Hero />
      </nav>
    </div>
  );
};

export default Navbar;

const Hamburger = styled.div`
  z-index: 1002;
  transform: ${({ open }) => (open ? "scale(0)" : "scale(1)")};
  transition: all 0.5s ease-in-out;
`;

const HamburgerClose = styled.div`
  z-index: 1002;
  transform: ${({ open }) => (open ? "scale(1)" : "scale(0)")};
  transition: all 0.5s ease-in-out;
`;

const StyledLogo = styled.div`
  position: relative;
  width: ${(props) => (props.resizeLogo ? "200px" : "180px")};
  height: ${(props) => (props.resizeLogo ? "60px" : "50px")};
  top: -5px;
  transition: all 0.5s ease-in-out;
`;

const StyledUl = styled.ul`
  z-index: 1001;
  position: fixed;

  @media (min-width: 640px) {
    li:nth-child(-n + 3) {
      text-decoration: none;
      list-style-type: none;

      a {
        text-decoration: none;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          background: rgba(79, 70, 229);
          transform: translateY(50px);
          opacity: 0;
        }

        &:hover:after {
          transform: translateY(0px);
          transition: 0.4s;
          opacity: 1;
        }
      }
    }
  }
`;

const StyledNavOptions = styled.ul`
  @media (max-width: 640px) {
    position: fixed;
    z-index: 1000;
    padding: 2rem;
    text-align: center;
    background-color: #fff;
    border-radius: 1.5rem;
    width: 90%;
    right: 5%;
    transition: transform 0.75s;
    transform: ${({ open }) =>
      open ? "translateY(20px)" : "translateY(-500px)"};

    li {
      padding: 1rem 0;
    }
  }
`;
