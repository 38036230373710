import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { Toaster } from "react-hot-toast";
import AboutMe from "../components/AboutMe";

import dynamic from "next/dynamic";
import { Suspense } from "react";
import Navbar from "../components/Navbar";

const DynamicRecentWork = dynamic(() => import("../components/RecentWork"), {
  suspense: true,
});
const DynamicServices = dynamic(() => import("../components/Services"), {
  suspense: true,
});

const DynamicPricing = dynamic(() => import("../components/Pricing"), {
  suspense: true,
});
const DynamicContact = dynamic(() => import("../components/Contact"), {
  suspense: true,
});

const DynamicFooter = dynamic(() => import("../components/Footer"), {
  suspense: true,
});

const DynamicFrequentlyAskedQuestion = dynamic(
  () => import("../components/FrequentlyAskedQuestions"),
  {
    suspense: true,
  }
);

const DynamicTechnologies = dynamic(
  () => import("../components/Technologies"),
  {
    suspense: true,
  }
);
export default function Home() {
  return (
    <div>
      <Head>
        <title>Montreal Web Development Company | WebFlex</title>
        <link rel="icon" href="/images/favicon.ico" />

        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap"
          rel="stylesheet"
        ></link>

        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content="Website Development Company." />
      </Head>
      <main>
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 10000,
          }}
        />
        <Navbar />
        <AboutMe />
        <Suspense fallback={"Loading..."}>
          <DynamicRecentWork />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicTechnologies />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicServices />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicPricing />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicContact />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicFrequentlyAskedQuestion />
        </Suspense>
        <Suspense fallback={"Loading..."}>
          <DynamicFooter />
        </Suspense>
      </main>
    </div>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      // Will be passed to the page component as props
    },
  };
}
