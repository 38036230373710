import { useTranslation } from "next-i18next";
import Image from "next/image";
import Tilt from "react-parallax-tilt";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <div
      id="hero"
      className="pt-40 lg:flex lg:justify-center lg:items-center w-4/5 mx-auto "
    >
      <div className="leading-10 text-black text-center">
        <h1 className=" text-3xl font-bold  text-indigo-600 py-8 md:text-4xl md:py-10 lg:py-14 lg:text-5xl leading-4">
          {t("grow-your-brand")}
        </h1>
        <h1 className="text-2xl leading-10 font-extralight md:text-2xl">
          {t("flexible-in-house")}
        </h1>
      </div>
      <div className="lg:flex lg:justify-center">
        <Tilt transitionSpeed={2000}>
          <div className="relative">
            <Image
              priority={true}
              src={"/images/website-building.svg"}
              width={800}
              height={550}
              alt="office"
            ></Image>
          </div>
        </Tilt>
      </div>
    </div>
  );
};

export default Hero;
