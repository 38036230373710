import { useTranslation } from "next-i18next";

const AboutMe = () => {
  const { t } = useTranslation();
  return (
    <div
      id="AboutUs"
      className="bg-indigo-600 w-4/5 mx-auto rounded-full py-20 px-6 relative -bottom-20 text-white"
    >
      <div className="text-center w-4/5 mx-auto leading-10 md:3/5">
        <h1 className="text-1xl font-bold md:text-4xl ">
          {t("about-us-title")}
        </h1>
        <p className="pt-4">{t("about-us-detail")}</p>
      </div>
    </div>
  );
};

export default AboutMe;
